.jsoneditor-react-container {
  width: 100%;
  height: 100%;
}

.jsoneditor-value.jsoneditor-object {
  display: none;
}

.jsoneditor-value.jsoneditor-array {
  display: none;
}

.jsoneditor {
  border: 2px solid #2c2c2c;
}

.jsoneditor-menu {
  background-color: #2c2c2c;
  border-bottom: none;
}
.jsoneditor-menu > button {
  margin: 0 !important;
}
.jsoneditor-menu > button.jsoneditor-separator, .jsoneditor-menu > .jsoneditor-modes > button.jsoneditor-separator {
  margin-left: 0 !important;
}
.jsoneditor-search input {
  width: 75px;
}