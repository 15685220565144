.img-container {
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  cursor: zoom-in;
}

.img-container div {
  transition: .5s ease;
  opacity: 1;
}

