@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.7s;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}